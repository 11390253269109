.dropdown-toggle::after{
  border: none !important;
  font-size: 20px;
  content: "\203A" !important;
  position: absolute;
  right: 6px;

}
.dropdown-toggle::before{
display: none !important;
}

.navbar .dropdown-toggle::after{
  transform: rotate(90deg);
  display: inline-flex;
}
.sidebar:not([data-submenuconfig="SSM"]) .dropdown-toggle::after{
  display: inline-flex;
}

.dropdown-menu{
  box-shadow: 0 0 50px 0 rgba(82,63,105,.15);
    border: none;
}
.dropdown-menu .dropdown-item.active,
.dropdown-menu .dropdown-item:hover{
  background: #f4f4ff !important;
  
}
.dropdown-menu .dropdown-item{
font-size: 0.8rem;
cursor: pointer;
display: flex;
margin:0 !important;
padding: 8px 16px 8px 16px;
align-items: center;
justify-content: flex-start;
flex-direction: row;
color: rgba(0,0,0,0.8) !important;
}

.app-container[data-grid="S1"] .sidebar .dropdown-toggle::after{
  display: none;
}
.app-container[data-grid="S2"] .sidebar .dropdown-toggle::after{
  display: none;
}

