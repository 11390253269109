.sidebar .nav a,
.sidebar .nav a i,
.sidebar .nav span,
.navbar-collapse .navbar-nav .nav-link,
.animation-transition-general,
.sidebar .nav span,
.off-canvas-sidebar .nav span {
  -webkit-transition: all 300ms ease 0s;
  -moz-transition: all 300ms ease 0s;
  -o-transition: all 300ms ease 0s;
  -ms-transition: all 300ms ease 0s;
  transition: all 300ms ease 0s;
}

.wrapper {
  position: relative;
  top: 0;
  height: 100vh;
}
.wrapper.wrapper-full-page {
  min-height: 100vh;
  height: auto;
}

.sidebar,
.off-canvas-sidebar {
  transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}
.sidebar .sidebar-wrapper,
.off-canvas-sidebar .sidebar-wrapper {
  width: 100%;
  min-height: 100%;
  max-height: calc(100vh - 705px);
  z-index: 4;
  position: relative;
  overflow: auto;
  flex-direction: column;
}
.sidebar .nav,
.off-canvas-sidebar .nav {
  margin-top: 20px;
  display: block;
}

.sidebar .nav li > a + div .nav,
.off-canvas-sidebar .nav li > a + div .nav {
  margin-top: 5px;
}
.sidebar .nav li > a + div .nav li > a,
.off-canvas-sidebar .nav li > a + div .nav li > a {
  margin-top: 0px;
  padding: 8px 8px;
}
.sidebar .nav li > a,
.off-canvas-sidebar .nav li > a {
  border-radius: 30px;
  display: block;
  text-decoration: none;
  position: relative;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 0.62475rem;
  padding: 10px 20px;
  line-height: 1.625rem;
}
/* .sidebar .nav li:first-child > a,
.off-canvas-sidebar .nav li:first-child > a {
  margin: 0 15px;
} */
.sidebar .nav span,
.off-canvas-sidebar .nav span {
  margin: 0.5px 0px;
  /* line-height: 30px; */
  position: relative;
  display: block;
  height: auto;
  /* white-space: nowrap; */
}
.sidebar .nav i,
.off-canvas-sidebar .nav i {
  font-size: 20px;
  float: left;
  margin-right: 8px;
  line-height: 30px;
  width: 34px;
  text-align: center;

  position: relative;
}

/*........................Small Screen..............*/
@media screen and (max-width: 991px) {
  .sidebar {
    position: fixed;
    display: block;
    top: 0;
    height: 100%;
    width: 260px;
    right: auto;
    left: 0;
    margin: 0;
    border-radius: 0;
    z-index: 1032;
    visibility: visible;
    overflow-y: visible;
    padding: 0;
    transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -webkit-transform: translate3d(-260px, 0, 0);
    -moz-transform: translate3d(-260px, 0, 0);
    -o-transform: translate3d(-260px, 0, 0);
    -ms-transform: translate3d(-260px, 0, 0);
    transform: translate3d(-260px, 0, 0);
  }
}
@media screen and (max-width: 991px) and (prefers-reduced-motion: reduce) {
  .sidebar {
    transition: none;
  }
}

@media screen and (max-width: 991px) {
  .wrapper {
    transition: 0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
}
@media screen and (max-width: 991px) and (prefers-reduced-motion: reduce) {
  .wrapper {
    transition: none;
  }
}

@media screen and (max-width: 991px) {
  .main-panel {
    width: 100%;
  }
  .main-panel .content {
    padding-left: 30px;
  }
  .nav-open .main-panel {
    right: 0;
    -webkit-transform: translate3d(260px, 0, 0);
    -moz-transform: translate3d(260px, 0, 0);
    -o-transform: translate3d(260px, 0, 0);
    -ms-transform: translate3d(260px, 0, 0);
    transform: translate3d(260px, 0, 0);
  }
  .nav-open .sidebar {
    transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
}
@media screen and (max-width: 991px) and (prefers-reduced-motion: reduce) {
  .nav-open .sidebar {
    transition: none;
  }
}

@media screen and (max-width: 991px) {
  .nav-open .sidebar:before {
    content: unset;
  }
  .nav-open body {
    position: relative;
    overflow-x: hidden;
  }
  .nav-open .menu-on-right .main-panel {
    -webkit-transform: translate3d(-260px, 0, 0);
    -moz-transform: translate3d(-260px, 0, 0);
    -o-transform: translate3d(-260px, 0, 0);
    -ms-transform: translate3d(-260px, 0, 0);
    transform: translate3d(-260px, 0, 0);
  }
  .nav-open .menu-on-right .navbar-collapse,
  .nav-open .menu-on-right .sidebar {
    -webkit-transform: translate3d(0px, 0, 0);
    -moz-transform: translate3d(0px, 0, 0);
    -o-transform: translate3d(0px, 0, 0);
    -ms-transform: translate3d(0px, 0, 0);
    transform: translate3d(0px, 0, 0);
  }
  .nav-open .menu-on-right #bodyClick {
    right: 260px;
    left: auto;
  }
  .nav-open .fixed-plugin {
    display: none;
  }
  .menu-on-right .sidebar {
    left: auto;
    right: 0;
    -webkit-transform: translate3d(260px, 0, 0);
    -moz-transform: translate3d(260px, 0, 0);
    -o-transform: translate3d(260px, 0, 0);
    -ms-transform: translate3d(260px, 0, 0);
    transform: translate3d(260px, 0, 0);
  }
  #bodyClick {
    height: 100%;
    width: 100%;
    position: fixed;
    opacity: 1;
    top: 0;
    right: 0;
    left: 260px;
    content: "";
    z-index: 9999;
    overflow-x: hidden;
    background-color: transparent;
    transition: 0.5s, cubic-bezier(0.685, 0.0473, 0.346, 1);
  }
}
@media screen and (max-width: 991px) and (prefers-reduced-motion: reduce) {
  #bodyClick {
    transition: none;
  }
}

@media screen and (max-width: 991px) {
  .navbar .dropdown-menu {
    position: static !important;
  }
}

@media screen and (max-width: 768px) {
  .main-panel .content {
    padding-left: 15px;
    padding-right: 15px;
  }
}
/*................................................*/
/*...............Main App Backgroun .........*/
.wrapper[data-appmode="light"] {
  background: #f2f2f2;
  color: #000;
  font-weight: normal;
}
.wrapper[data-appmode="light"] .sidebar {
  box-shadow: unset;
}

.wrapper[data-appmode="dark"] {
  background: #1e1e2f;
  color: #fff;
  font-weight: lighter;
  /* background: linear-gradient(#1a1e34, #1a1e3f); */
}
.wrapper[data-appmode="dark"] .sidebar {
  box-shadow: none;
}

/*.......................................*/

@media screen and (min-width: 992px) {
  /*.............general Changes .................*/
  .app-container[data-sidenavonly="TRUE"] .sidebar-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .sidebar .nav li a {
    border-radius: unset;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all ease-in 300ms;
    line-height: 15px;
  }
  .sidebar:not([data-template="S0"]) .nav li > a {
    text-align: center;
  }
  .sidebar[data-theme="light"] {
    background: white;
  }
  .sidebar {
    padding-top: 5px;
  }
  .sidebar[data-submenuconfig="DSM"] .sidebar-wrapper {
    overflow: unset !important;
  }
  .sidebar[data-submenuconfig="SSM"] .nav li {
    flex-direction: column;
  }
  .collapse-menu-item {
    background: #0000002b;
  }

  /*
  made changes to accomodate submenu in case of s2 and s1 grid config
  padding-left: 25px;
  justify-content: flex-start;
  */
  /* .sidebar[data-submenuconfig="SSM"] .nav li .collapse-menu-item  a{
  

  align-items: center;
  display: flex;
  justify-content: start;
} */

  .dropdown-menu::before {
    content: unset;
  }

  .sidebar[data-theme="light"] .nav li:hover > a span,
  .sidebar[data-theme="light"] .nav li:hover > a i,
  .sidebar[data-theme="light"] .nav li > a.active span,
  .sidebar[data-theme="light"] .nav li > a.active i {
    color: rgba(0, 0, 0, 1);
  }
  .sidebar[data-theme="light"] .nav li > a span,
  .sidebar[data-theme="light"] .nav li > a i,
  .sidebar[data-theme="light"] .nav li > a {
    color: rgba(0, 0, 0, 0.8);
  }
  .sidebar[data-theme="light"] .nav li > a.active,
  .sidebar[data-theme="light"] .nav li > a:hover {
    background: #00000c17;
  }
  /* .sidebar[data-template="S2"][data-theme="light"] .nav a span {
    background: white;
  } */
  .sidebar[data-theme="dark"] {
    /* background: linear-gradient(#1a1e34, #1a1e3f); */
    background: linear-gradient(#1e1e2f, #1e1e24);
  }

  .sidebar[data-theme="dark"] .nav li:hover > a span,
  .sidebar[data-theme="dark"] .nav li:hover > a i,
  .sidebar[data-theme="dark"] .nav li > a.active span,
  .sidebar[data-theme="dark"] .nav li > a.active i,
  .sidebar[data-theme="dark"] .nav li.active > a {
    color: rgba(255, 255, 255, 1);
  }
  .sidebar[data-theme="dark"] .nav li > a span,
  .sidebar[data-theme="dark"] .nav li > a i,
  .sidebar[data-theme="dark"] .nav li > a {
    color: rgba(255, 255, 255, 0.8);
  }

  .sidebar[data-theme="dark"] .nav li > a.active,
  .sidebar[data-theme="dark"] .nav li > a:hover {
    background: #fbfbfd12;
  }

  .sidebar[data-theme="transparent"] {
    background: transparent;
  }

  .sidebar {
    height: 100%;
    width: 100%;
    margin: 0px;
    position: relative;
    border-radius: 0px;
  }

  .sidebar .nav {
    margin-top: 0px;
  }

  .sidebar .nav li {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 8px;
  }

  /*........Template 0 .........*/

  .sidebar[data-template="S0"] .nav li > a > span {
    flex-grow: 1;
  }
  .sidebar[data-template="S0"] .nav li > a i:nth-of-type(2) {
    flex: 0;
  }

  /*...............................*/

  /*........Template 1 .........*/

  .sidebar[data-template="S1"] .nav li a:not(.dropdown-item) {
    flex-direction: column;
  }
  .sidebar[data-template="S1"] .nav li a:not(.dropdown-item) span {
    flex-direction: column;
    padding-top: 10px;
  }

  .sidebar[data-template="S1"] .nav a i {
    margin-right: 0px;
  }

  .sidebar[data-template="S1"] .nav span:nth-of-type(2) {
    line-height: 0px;
  }

  .sidebar[data-template="S1"] .dropdown-toggle::after {
    display: none;
  }
  /*...............................*/

  /*........Template 2 .........*/

  .sidebar[data-template="S2"] .nav > li > a > i {
    margin-right: 0px;
  }
  .sidebar[data-template="S2"] .nav > li > a > span {
    position: absolute;
    left: 150%;
    background: white !important;
    color: black !important;
    border-radius: 5px;
    padding: 10px;
    opacity: 0;
    transition: width 500ms;
    pointer-events: none;
  }
  .sidebar[data-template="S2"] .nav > li:hover > a > span {
    opacity: 1;
  }

  .sidebar[data-template="S2"] .sidebar-wrapper {
    overflow: unset !important;
  }
  .sidebar[data-template="S2"] .dropdown-toggle::after {
    display: none;
  }

  /*...............................*/

  /*.............Link Template 1 .................*/

  .sidebar[data-template="S2"][data-linktemplate="SLT1_ROUND"] .nav li > a {
    border-radius: 50%;
  }
  .sidebar[data-linktemplate="SLT1_ROUNDED"] .nav li > a {
    border-radius: 5px;
  }

  /*............. .................*/
  /*.............Link Template 2 .................*/

  .sidebar[data-linktemplate="SLT2"] .nav li {
    padding: 0.5px 0px;
  }

  /*............. .................*/
}

.sidebar-wrapper.center {
  padding-bottom: 25px;
}
