/*     brand Colors              */
/* navbar color */
.modal-content {
  border: 0;
}
.modal-content .modal-header {
  border-bottom: none;
}
.modal-content .modal-header button {
  position: absolute;
  right: 27px;
  top: 24px;
  outline: 0;
  padding: 1rem;
  margin: -1rem -1rem -1rem auto;
}
.modal-content .modal-header .title {
  color: #222a42;
  margin-top: 5px;
  margin-bottom: 0;
}
.modal-content .modal-header .modal-title {
  color: #222a42;
}
.modal-content .modal-header i.tim-icons {
  font-size: 16px;
}
.modal-content .modal-body {
  line-height: 1.9;
}
.modal-content .modal-body p {
  color: #222a42;
}
.modal-content .modal-footer {
  border-top: 0;
  -webkit-justify-content: space-between;
  /* Safari 6.1+ */
  justify-content: space-between;
}
.modal-content .modal-footer button {
  margin: 0;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
}
.modal-content .modal-footer button.pull-left {
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  left: -5px;
}
.modal-content .modal-body + .modal-footer {
  padding-top: 0;
}

.modal-backdrop {
  background: rgba(0, 0, 0, 0.3);
}

.modal.modal-default .modal-content {
  background-color: #ffffff;
  color: #222a42;
}

.modal.modal-default .modal-body p {
  color: rgba(255, 255, 255, 0.8);
}

.modal .modal-header .close {
  color: #fd5d93;
  text-shadow: none;
}
.modal .modal-header .close:hover,
.modal .modal-header .close:focus {
  opacity: 1;
}

.modal.modal-black .modal-content {
  background: linear-gradient(to bottom, #222a42 0%, #1d253b 100%);
  color: rgba(255, 255, 255, 0.8);
}
.modal.modal-black .modal-content .modal-header .modal-title,
.modal.modal-black .modal-content .modal-header .title {
  color: rgba(255, 255, 255, 0.9);
}
.modal.modal-black .modal-content .modal-body p {
  color: rgba(255, 255, 255, 0.8);
}

.modal.modal-black h1,
.modal.modal-black h2,
.modal.modal-black h3,
.modal.modal-black h4,
.modal.modal-black h5,
.modal.modal-black h6,
.modal.modal-black p {
  color: #ffffff;
}

.modal-search .modal-dialog {
  margin: 20px auto;
  max-width: 650px;
}

.modal-search .modal-dialog span {
  font-size: 35px;
  color: #b7b7b7;
}

.modal-search .modal-content .modal-header {
  padding: 24px;
}

.modal-search .modal-header .close {
  color: #555555;
  top: 30px !important;
}

.modal-search .modal-footer {
  border-top: 2px solid #f9f9f9;
  margin: 0px 25px 20px;
}
