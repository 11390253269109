input[type=range] {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: #007bff;
    border-radius: 25px;
    border: 0px solid #000101;
  }
  input[type=range]::-webkit-slider-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 15px;
    width: 15px;
    border-radius: 7px;
    background: #007bff;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6px;
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: #007bff;
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
   
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: #007bff;
    border-radius: 25px;
    border: 0px solid #000101;
  }
  input[type=range]::-moz-range-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 15px;
    width: 15px;
    border-radius: 7px;
    background: #007bff;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 1px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 39px 0;
    color: transparent;
  }
  input[type=range]::-ms-fill-lower {
    background: #007bff;
    border: 0px solid #000101;
    border-radius: 50px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  }
  input[type=range]::-ms-fill-upper {
    background: #007bff;
    border: 0px solid #000101;
    border-radius: 50px;
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  }
  input[type=range]::-ms-thumb {
    box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    border: 0px solid #000000;
    height: 15px;
    width: 15px;
    border-radius: 7px;
    background: #007bff;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: #007bff;
  }
  input[type=range]:focus::-ms-fill-upper {
    background: #007bff;
  }