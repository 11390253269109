.wrapper[data-appmode="dark"] .custom-inp-container .bar {
  background: rgba(255, 255, 255, 0.95);
}
.wrapper[data-appmode="dark"]
  .input_group.input_unfocused.input_hasValue.input_valid
  label.input_label {
  color: rgba(255, 255, 255, 0.95);
}

.wrapper[data-appmode="light"] .custom-inp-container .bar {
  background: rgba(0, 0, 0, 0.54);
}
.wrapper[data-appmode="light"]
  .input_group.input_unfocused.input_hasValue.input_valid
  label.input_label {
  color: rgba(0, 0, 0, 0.55);
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.reactSelect .is-open {
  position: relative;
  z-index: 1000;
}
.input_group {
  display: block;
  position: relative;
  width: 100%;
  min-height: 50px;
  margin-bottom: 10px;
  z-index: 0;
}
.input_group .bar {
  display: none;
}
@media only screen and (min-width: 0px) and (max-width: 870px) {
  .input_group {
    min-height: 64px;
    margin-bottom: 0;
  }
}
.input_group label.input_label {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  z-index: 10;
  cursor: text;
  padding: 0 0 0 15px;
}
.input_group label.input_label .label_text {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.7px;
}
@media only screen and (min-width: 0px) and (max-width: 870px) {
  .input_group label.input_label .label_text {
    font-size: 16px;
    font-weight: 300;
  }
}
@media only screen and (min-width: 0px) and (max-width: 870px) {
  .input_group label.input_label {
    height: 64px;
  }
}

.input_group.input_focused label.input_label,
.input_group.input_hasValue label.input_label {
  height: 35px;
}
@media only screen and (min-width: 0px) and (max-width: 870px) {
  .input_group.input_focused label.input_label,
  .input_group.input_hasValue label.input_label {
    height: 40px;
  }
}
.input_group.input_focused label.input_label .label_text,
.input_group.input_hasValue label.input_label .label_text {
  letter-spacing: 0.7px;
  font-size: 11px;
}
.input_group label.input_label .label_text {
  color: #17a2b8;
}

.input_group input.input {
  display: block;
  position: relative;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: 100%;
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.7s ease-in-out;
  -moz-transition: all 0.7s ease-in-out;
  -ms-transition: all 0.7s ease-in-out;
  -o-transition: all 0.7s ease-in-out;
  transition: all 0.7s ease-in-out;
  z-index: 1;
  -webkit-appearance: none;
  border: none;
  outline: none;
  padding: 18px 15px 0 15px;
  margin: 0 auto 13px auto;

  letter-spacing: 0.7px;
  font-family: "Poppins", "Roboto" sans-serif;
  font-weight: 300;
  font-size: 14px;
  height: 50px;
  color: inherit;
}
@media only screen and (min-width: 0px) and (max-width: 870px) {
  .input_group input.input {
    font-size: 14px;
    padding: 15px 15px 0 15px;
    height: 64px;
  }
}
.input_group input.input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  color: #cacaca;
}
.input_group i {
  top: 0;
  right: 15px;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: 20px;
  height: 50px;
  z-index: 1000;
  position: absolute;
  z-index: 100;
}
@media only screen and (min-width: 0px) and (max-width: 870px) {
  .input_group i {
    height: 64px;
  }
}
.input_group i svg {
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  width: 20px;
  height: 20px;
}
.input_group i.input_error_icon {
  z-index: 10;
}
.input_group i.input_error_icon path {
  fill: #f16767;
}
.input_group i.input_valid_icon {
  z-index: 1;
}
.input_group i.input_clear_icon {
  z-index: 1;
}
.input_group i.input_valid_icon path {
  fill: #50b87f;
}
.input_group.input_error i.input_error_icon svg {
  opacity: 1;
}
.input_group.input_valid i.input_error_icon {
  z-index: 1;
}
.input_group.input_valid i.input_valid_icon {
  z-index: 10;
}
.input_group.input_focused i.input_clear_icon {
  right: 40px;
  z-index: 10;
}
.input_group.input_valid.input_hasValue i.input_valid_icon svg {
  opacity: 1;
}
.input_group.input_valid.input_hasValue.input_focused i.input_valid_icon svg {
  opacity: 0;
}
.input_group.input_focused.input_hasValue i.input_clear_icon svg {
  opacity: 1;
}
.error_container {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 100%;
  white-space: nowrap;
  line-height: 50px;
  width: auto;
  height: 50px;
  background: #f16767;
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 20px 0 20px;
  color: white;
  font-size: 14px;
  font-weight: 100;
  letter-spacing: 0.6px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
@media only screen and (min-width: 0px) and (max-width: 870px) {
  .error_container {
    position: relative;
    top: -13px;
    left: 0;
    line-height: 30px;
    font-size: 12px;
    padding: 0 15px 0 15px;
    font-weight: 300;
  }
}
.error_container.visible {
  -o-transform: translateX(0px);
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
  opacity: 1;
}
@media only screen and (min-width: 0px) and (max-width: 870px) {
  .error_container.visible {
    height: 30px;
  }
}
.error_container.invisible {
  -o-transform: translateX(-30px);
  -webkit-transform: translateX(-30px);
  -moz-transform: translateX(-30px);
  -ms-transform: translateX(-30px);
  transform: translateX(-30px);
  opacity: 0;
  -webkit-animation-delay: 2s;
  /* Chrome, Safari, Opera */
  animation-delay: 2s;
}
@media only screen and (min-width: 0px) and (max-width: 870px) {
  .error_container.invisible {
    height: 0;
    line-height: 0;
    -o-transform: translateX(0px);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    transform: translateX(0px);
  }
}
.password_validator {
  top: 100%;
  right: 0%;
  position: absolute;
  display: block;
  opacity: 0;
  width: 320px;
  height: auto;
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  opacity: 1;
  z-index: 100;
}
.password_validator:after {
  display: block;
  position: absolute;
  top: 30px;
  left: -10px;
  content: "";
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #e4e7e8;
}
.password_validator .validator_container {
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -khtml-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  background: #e4e7e8;
  padding: 10px 0px 10px 20px;
}
.password_validator .validator_container h4.validator_title {
  display: block;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  margin: 12px 0 5px 0;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: #363b4a;
  opacity: 0.25;
  letter-spacing: 1.5px;
}
.password_validator .validator_container h4.validator_title.valid {
  color: #50b87f;
  opacity: 1;
}
.password_validator .validator_container ul.rules_list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
}
.password_validator .validator_container ul.rules_list > li {
  display: block;
  position: relative;
  margin: 12px 0 12px 0;
  font-size: 12px;
  letter-spacing: 0.3px;
  font-weight: 200;
  color: #363b4a;
}
.password_validator .validator_container ul.rules_list > li .bad_word {
  font-style: italic;
  padding: 0 5px 0 0;
}
.password_validator .validator_container ul.rules_list > li .bad_word:after {
  content: ",";
  display: inline;
}
.password_validator
  .validator_container
  ul.rules_list
  > li
  .bad_word:last-child {
  padding: 0;
}
.password_validator
  .validator_container
  ul.rules_list
  > li
  .bad_word:last-child:after {
  display: none;
}
.password_validator .validator_container ul.rules_list > li .icon_invalid {
  display: block;
  position: absolute;
  top: 1px;
  left: 0;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
  width: 18px;
  height: 18px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.password_validator .validator_container ul.rules_list > li .icon_invalid svg {
  opacity: 1;
  width: 18px;
  height: 18px;
}
.password_validator
  .validator_container
  ul.rules_list
  > li
  .icon_invalid
  svg
  path {
  fill: #f16767;
}
.password_validator .validator_container ul.rules_list > li .icon_valid {
  position: absolute;
  top: 1px;
  left: 0;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  opacity: 0;
  width: 19px;
  height: 19px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.password_validator .validator_container ul.rules_list > li .icon_valid svg {
  opacity: 1;
  width: 19px;
  height: 19px;
}
.password_validator .validator_container ul.rules_list > li .error_message {
  display: block;
  position: relative;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  padding: 0 0 0 27px;
  opacity: 0.9;
}
.password_validator
  .validator_container
  ul.rules_list
  > li.valid
  .icon_invalid {
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}
.password_validator .validator_container ul.rules_list > li.valid .icon_valid {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}
.password_validator
  .validator_container
  ul.rules_list
  > li.valid
  .error_message {
  opacity: 0.3;
}
.password_validator.visible {
  -o-transform: translateX(0px);
  -webkit-transform: translateX(0px);
  -moz-transform: translateX(0px);
  -ms-transform: translateX(0px);
  transform: translateX(0px);
  opacity: 1;
}
.password_validator.invisible {
  -o-transform: translateX(-15px);
  -webkit-transform: translateX(-15px);
  -moz-transform: translateX(-15px);
  -ms-transform: translateX(-15px);
  transform: translateX(-15px);
  opacity: 0;
  -webkit-animation-delay: 2s;
  /* Chrome, Safari, Opera */
  animation-delay: 2s;
}

/*........Rounded Template........*/
.input_group.rounded input.input {
  border-radius: 50px;
}
/*...........................*/

/*........Fixed Label Template........*/
.input_group.fixedLabel label.input_label .label_text {
  letter-spacing: 0.7px;
  font-size: 11px;
}
.input_group.fixedLabel label.input_label {
  height: 35px;
}
/*...........................*/

/*.........Material ......*/
.input_group.material .bar {
  display: block;
  height: 1px;
  margin: 0px 15px 0px 15px;
  transition: 0.5s ease all;
  -moz-transition: 0.5s ease all;
  -webkit-transition: 0.5s ease all;
}
.input_group.material input.input {
  margin: 0 auto 0px auto;
  background: transparent;
}
.input_group.material.input_focused .bar {
  background: #17a2b8;
  height: 2px;
}
/*................Boxed...............*/
.input_group.boxed {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  max-height: 50px;
}

.input_group.boxed.input_focused label.input_label,
.input_group.boxed.input_hasValue label.input_label {
  height: 25px;
}
.input_group.boxed input.input {
  height: 40px;
  background: transparent;
  color: inherit;
}

/*..................................*/
/*................transparent.............*/
.input_group.transparent input.input {
  background: transparent;
  color: inherit;
}
.input_group.transparent label.input_label .label_text {
  color: inherit;
}

/*..................................*/
.bootstrap.input_group i.input_error_icon {
  top: 20px !important;
}
