html {
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(34, 42, 66, 0);
}
button {
  font-size: 13px !important;
}

@-ms-viewport {
  width: device-width;
}
.nav-link {
  cursor: pointer;
}
body {
  font-family: "Poppins", sans-serif;
  font-size: 0.875rem;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}
a:not([href]):not([tabindex]):focus {
  outline: 0;
}

caption {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
}

h1,
.h1 {
  font-size: 2.0625rem;
}

h2,
.h2 {
  font-size: 1.6875rem;
}

h3,
.h3 {
  font-size: 1.4375rem;
}

h4,
.h4 {
  font-size: 1.0625rem;
}

h5,
.h5 {
  font-size: 0.8125rem;
}

h6,
.h6 {
  font-size: 0.75rem;
}

.lead {
  font-size: 0.78125rem;
  font-weight: 300;
}

.display-1 {
  font-size: 3.3rem;
  font-weight: 600;
  line-height: 1.2;
}

.display-2 {
  font-size: 2.75rem;
  font-weight: 600;
  line-height: 1.2;
}

.display-3 {
  font-size: 2.1875rem;
  font-weight: 600;
  line-height: 1.2;
}

.display-4 {
  font-size: 1.6275rem;
  font-weight: 600;
  line-height: 1.2;
}

.blockquote {
  font-size: 0.9625rem;
}

.img-thumbnail {
  box-shadow: 0 1px 2px rgba(34, 42, 66, 0.075);
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md,
.col-md-auto,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg,
.col-lg-auto,
.col-xl-1,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl,
.col-xl-auto {
  min-height: 1px;
}

.table th,
.table td {
  padding: 1rem;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-responsive {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.page-link {
  padding: 0 0.6875rem;
  margin-left: -0.0625rem;
}

.page-link:focus {
  z-index: 2;
  box-shadow: none;
}
.page-link:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.pagination-lg .page-link {
  font-size: 0.99925rem;
  line-height: 1.625rem;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.4285rem;
  border-bottom-left-radius: 0.4285rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.4285rem;
  border-bottom-right-radius: 0.4285rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2857rem;
  border-bottom-left-radius: 0.2857rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2857rem;
  border-bottom-right-radius: 0.2857rem;
}

.list-group-item {
  padding: 1rem 1rem;
  margin-bottom: -0.0625rem;

  border: 0.0625rem solid #e9ecef;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(34, 42, 66, 0.075) !important;
}

.shadow {
  box-shadow: 0 1px 20px 0px rgba(0, 0, 0, 0.1) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(34, 42, 66, 0.175) !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

img {
  max-width: 100%;
  border-radius: 0.2857rem;
}

.img-raised {
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

p {
  font-weight: 300;
}

button,
input,
optgroup,
select,
textarea {
  font-family: "Poppins", sans-serif;
}

a {
  font-weight: 300;
}

h1,
.h1 {
  line-height: 1.05;
  margin-bottom: 30px;
}
h1 small,
.h1 small {
  font-weight: 600;
  text-transform: uppercase;
  opacity: 0.8;
}

h2,
.h2 {
  margin-bottom: 30px;
  line-height: 1.2;
}

h3,
.h3 {
  margin-bottom: 30px;
  line-height: 1.4em;
}

h4,
.h4 {
  line-height: 1.45em;
  margin-bottom: 15px;
}

h5,
.h5 {
  line-height: 1.4em;
  margin-bottom: 15px;
}

h6,
.h6 {
  font-weight: 600;
}

p {
  margin-bottom: 5px;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.table .form-check {
  margin: 0;
  margin-top: 5px;
}
.table .form-check label .form-check-sign::before,
.table .form-check label .form-check-sign::after {
  top: -17px;
  left: 4px;
}

.table .btn {
  margin: 0;
}

.table small,
.table .small {
  font-weight: 300;
}

.table > thead > tr > th {
  border-bottom-width: 1px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 700;
  border: 0;
}

.table .radio,
.table .checkbox {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  width: 15px;
}
.table .radio .icons,
.table .checkbox .icons {
  position: relative;
}
.table .radio label:after,
.table .radio label:before,
.table .checkbox label:after,
.table .checkbox label:before {
  top: -17px;
  left: -3px;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 12px 7px;
  vertical-align: middle;
}

.table .th-description {
  max-width: 150px;
}

.table > tbody > tr {
  position: relative;
}

.table > tfoot > tr {
  text-transform: uppercase;
}

.table-responsive {
  overflow: scroll;
  padding-bottom: 10px;
}

#tables .table-responsive {
  margin-bottom: 30px;
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, 30%);
  transform: translate(0, 30%);
}

.pointer {
  cursor: pointer;
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-highlight {
  color: #18cae6 !important;
}
button:disabled {
  opacity: 0.6;
}

.react-select__control {
  background: transparent !important;
  border-color: #007bff !important;
}

.wrapper[data-appmode="dark"] .react-select__input,
.wrapper[data-appmode="dark"] .react-select__single-value {
  color: white;
}

.react-select__option {
  color: rgba(0, 0, 0, 1) !important;
}

::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 7px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #344675;
}

/*# sourceMappingURL=black-dashboard-react.css.map */
html,
body,
#root {
  height: 100%;
  margin: 0px;
  padding: 0px;
}

.app-main {
  padding: 5px;
}
.scroller .container-fluid,
.scroller .container-fluid .row {
  height: 100%;
}
.location-select__control {
  background: hsl(240deg 12% 98% / 5%) !important;
  width: 200px;
  border-color: transparent !important;
  color: #ffffff;
}
.location-select__menu {
  width: 200px !important;
}
.location-select__option,
.location-select__menu-list {
  color: black;
}
.location-select__single-value {
  color: #bad9fb !important;
}

.themed-select__control {
  border-color: hsl(0deg 6% 90%) !important;
  height: 48px;
}
.themed-select__placeholder,
.themed-select__single-value {
  color: #17a2b8 !important;

  font-weight: 400;
}
