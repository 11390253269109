@media screen and (min-width: 992px) {
  /* html,
body,
#root,
.wrapper,
.app-container{
    padding: 0;
    margin:0;
    height: 100%;
    width: 100%;
} */

  .app-topnav {
    grid-area: header;
    grid-column-end: col-end;
    grid-row-start: row-start;
  }
  .app-sidenav {
    grid-area: menu;
    grid-row-end: row-end;
  }
  .app-main {
    grid-area: main;
    grid-column-end: col-end;
    grid-row-end: row-end;
  }
  .app-brand {
    grid-area: brand;
    grid-row-start: row-start;
    z-index: 1050;
  }

  .app-footer {
    grid-area: footer;
  }

  .app-container {
    display: grid;
    height: 100%;
  }
  .main-panel {
    border: none !important;
    min-height: 100%;
  }

  .wrapper {
    overflow: hidden;
  }

  /*..............Logo Styling...........*/
  .app-brand .brand-img > img {
    height: 100%;
    width: 100%;
    padding: 0px 10px;
    object-fit: contain;
  }
  .brand-img[data-theme="light"] {
    background: white;
  }
  .brand-img[data-theme="dark"] {
    background: #1e1e2f;
    /* background: #1a1e34; */
  }

  /*..............Layout...........*/
  /*..............SIDE_NAVIGATION Layout...........*/
  .app-container[data-layout="SIDE_NAVIGATION"] {
    grid-template-areas:
      "brand header header header header header"
      "menu main main main main main"
      "menu main main main main main";
    grid-template-rows: [row-start] 50px [row-1] 10px [row-2] 10px [row-3] 15px [row-4] 10px [row-5] 10px [row-6] 10px [row-7] 10px [row-8] 10px [row-9] 10px [row-10] auto [row-end];
    grid-template-columns: [col-start] 60px [col-1] 20px [col-2] 20px [col-3] 20px [col-4] 20px [col-5] 30px [col-6] 30px [col-7] 30px [col-8] 30px [col-9] 30px [col-10] 30px [col-11] auto [col-end];
  }

  /*..........................................*/
  /*...............Sidenav Only..........*/
  .app-container[data-layout="SIDE_NAVIGATION"][data-sidenavonly="TRUE"] {
    grid-template-areas:
      "brand main main main main main"
      "menu main main main main main"
      "menu main main main main main";
  }
  /*..........................................*/

  /*..............TOP_NAVIGATION Layout...........*/
  .app-container[data-layout="TOP_NAVIGATION"] {
    grid-template-areas:
      "brand header header header header header"
      "main main main main main main"
      "main main main main main main";
    grid-template-rows: [row-start] 50px [row-1] 10px [row-2] 10px [row-3] 15px [row-4] 10px [row-5] 10px [row-6] 10px [row-7] 10px [row-8] 10px [row-9] 10px [row-10] auto [row-end];

    grid-template-columns: [col-start] 60px [col-1] 20px [col-2] 20px [col-3] 20px [col-4] 20px [col-5] 30px [col-6] 30px [col-7] 30px [col-8] 30px [col-9] 30px [col-10] 30px [col-11] auto [col-end];
  }

  .app-container[data-logoonnav="TRUE"] .app-brand {
    z-index: 1092;
    height: calc(100% - 40px);
  }
  .app-container[data-logoonnav="TRUE"] .app-topnav {
    grid-column-start: 1;
  }

  /*..............Rounded...........*/
  .app-container[data-rounded="SLN"][data-padded="SLN"][data-boxed="SLN"]
    .navbar,
  .app-container[data-rounded="SLN"][data-padded="SLN"][data-boxed="SLN"]
    .sidebar,
  .app-container[data-rounded="SLN"][data-padded="SLN"][data-boxed="SLN"]
    .brand-img {
    border-radius: 5px;
  }
  .app-container[data-rounded="S_LN"][data-padded="SLN"][data-boxed="S_LN"]
    .navbar {
    border-radius: 0px 5px 5px 0px;
  }
  .app-container[data-rounded="S_LN"][data-padded="SLN"][data-boxed="S_LN"]
    .brand-img {
    border-radius: 5px 0px 0px 5px;
  }
  .app-container[data-rounded="S_LN"][data-padded="SLN"][data-boxed="S_LN"]
    .sidebar {
    border-radius: 5px;
  }
  .app-container[data-rounded="SL_N"][data-padded="SLN"][data-boxed="SL_N"]
    .navbar {
    border-radius: 5px;
  }
  .app-container[data-rounded="SL_N"][data-padded="SLN"][data-boxed="SL_N"]
    .brand-img {
    border-radius: 5px 5px 0px 0px;
  }
  .app-container[data-rounded="SL_N"][data-padded="SLN"][data-boxed="SL_N"]
    .sidebar {
    border-radius: 0px 0px 5px 5px;
  }
  .app-container[data-rounded="S_LN"][data-padded="S_LN"] .sidebar {
    border-radius: 5px;
  }

  /*..............Boxed...........*/
  .app-container[data-boxed="SLN"] .app-sidenav {
    padding-top: 5px !important;
  }
  .app-container[data-boxed="SLN"] .app-topnav {
    padding-left: 5px !important;
  }
  .app-container[data-boxed="S_LN"] .app-sidenav {
    padding-top: 5px !important;
  }
  .app-container[data-boxed="SL_N"] .app-topnav {
    padding-left: 5px !important;
  }

  /*..............Padded...........*/
  .app-container[data-padded="SLN"] {
    padding: 8px;
  }
  .app-container[data-padded="S_LN"] .app-sidenav {
    padding: 0px 5px 5px 5px;
  }

  /*........Nav and logo Height..............*/
  .app-container[data-topnavsize="XXXS"] .app-topnav,
  .app-container[data-logoheightsize="XXXS"] .app-brand {
    grid-row-end: row-1;
  }
  .app-container[data-topnavsize="XXS"] .app-topnav,
  .app-container[data-logoheightsize="XXS"] .app-brand {
    grid-row-end: row-2;
  }
  .app-container[data-topnavsize="XS"] .app-topnav,
  .app-container[data-logoheightsize="XS"] .app-brand {
    grid-row-end: row-3;
  }
  .app-container[data-topnavsize="S"] .app-topnav,
  .app-container[data-logoheightsize="S"] .app-brand {
    grid-row-end: row-4;
  }
  .app-container[data-topnavsize="M"] .app-topnav,
  .app-container[data-logoheightsize="M"] .app-brand {
    grid-row-end: row-5;
  }
  .app-container[data-topnavsize="L"] .app-topnav,
  .app-container[data-logoheightsize="L"] .app-brand {
    grid-row-end: row-6;
  }
  .app-container[data-topnavsize="XL"] .app-topnav,
  .app-container[data-logoheightsize="XL"] .app-brand {
    grid-row-end: row-7;
  }
  .app-container[data-topnavsize="XXL"] .app-topnav,
  .app-container[data-logoheightsize="XXL"] .app-brand {
    grid-row-end: row-8;
  }
  .app-container[data-topnavsize="XXXL"] .app-topnav,
  .app-container[data-logoheightsize="XXXL"] .app-brand {
    grid-row-end: row-9;
  }
  .app-container[data-topnavsize="XXXXL"] .app-topnav,
  .app-container[data-logoheightsize="XXXXL"] .app-brand {
    grid-row-end: row-10;
  }
  .app-container[data-topnavsize="XXXXXL"] .app-topnav,
  .app-container[data-logoheightsize="XXXXXL"] .app-brand {
    grid-row-end: row-11;
  }

  .app-container[data-topnavsize="XXXS"] .app-main,
  .app-container[data-logoheightsize="XXXS"] .app-sidenav {
    grid-row-start: row-1;
  }
  .app-container[data-topnavsize="XXS"] .app-main,
  .app-container[data-logoheightsize="XXS"] .app-sidenav {
    grid-row-start: row-2;
  }
  .app-container[data-topnavsize="XS"] .app-main,
  .app-container[data-logoheightsize="XS"] .app-sidenav {
    grid-row-start: row-3;
  }
  .app-container[data-topnavsize="S"] .app-main,
  .app-container[data-logoheightsize="S"] .app-sidenav {
    grid-row-start: row-4;
  }
  .app-container[data-topnavsize="M"] .app-main,
  .app-container[data-logoheightsize="M"] .app-sidenav {
    grid-row-start: row-5;
  }
  .app-container[data-topnavsize="L"] .app-main,
  .app-container[data-logoheightsize="L"] .app-sidenav {
    grid-row-start: row-6;
  }
  .app-container[data-topnavsize="XL"] .app-main,
  .app-container[data-logoheightsize="XL"] .app-sidenav {
    grid-row-start: row-7;
  }
  .app-container[data-topnavsize="XXL"] .app-main,
  .app-container[data-logoheightsize="XXL"] .app-sidenav {
    grid-row-start: row-8;
  }
  .app-container[data-topnavsize="XXXL"] .app-main,
  .app-container[data-logoheightsize="XXXL"] .app-sidenav {
    grid-row-start: row-9;
  }
  .app-container[data-topnavsize="XXXXL"] .app-main,
  .app-container[data-logoheightsize="XXXXL"] .app-sidenav {
    grid-row-start: row-10;
  }
  .app-container[data-topnavsize="XXXXXL"] .app-main,
  .app-container[data-logoheightsize="XXXXXL"] .app-sidenav {
    grid-row-start: row-11;
  }

  .app-container[data-topnavsize="XL"]
    .navbar[data-template="N1"]
    .navbar-nav.page-nav {
    padding: 10px 0px 10px 0px;
    height: 50px;
  }

  .app-container[data-logoonnav="TRUE"][data-topnavsize="XL"] .app-brand {
    height: calc(100% - 50px);
  }

  /*.................................*/

  /*........Sidebar width..............*/
  .app-container[data-sidenavsize="XXXS"] .app-sidenav {
    grid-column-end: col-1;
  }
  .app-container[data-sidenavsize="XXS"] .app-sidenav {
    grid-column-end: col-2;
  }
  .app-container[data-sidenavsize="XS"] .app-sidenav,
  .app-container[data-sidenavsize="XS_2"] .app-sidenav {
    grid-column-end: col-3;
  }
  .app-container[data-sidenavsize="S"] .app-sidenav {
    grid-column-end: col-4;
  }
  .app-container[data-sidenavsize="M"] .app-sidenav {
    grid-column-end: col-5;
  }
  .app-container[data-sidenavsize="L"] .app-sidenav {
    grid-column-end: col-6;
  }
  .app-container[data-sidenavsize="XL"] .app-sidenav {
    grid-column-end: col-7;
  }
  .app-container[data-sidenavsize="XXL"] .app-sidenav {
    grid-column-end: col-8;
  }
  .app-container[data-sidenavsize="XXXL"] .app-sidenav {
    grid-column-end: col-9;
  }
  .app-container[data-sidenavsize="XXXXL"] .app-sidenav {
    grid-column-end: col-10;
  }
  .app-container[data-sidenavsize="XXXXXL"] .app-sidenav {
    grid-column-end: col-11;
  }

  .app-container[data-sidenavsize="XXXS"] .app-main {
    grid-column-start: col-1;
  }
  .app-container[data-sidenavsize="XXS"] .app-main {
    grid-column-start: col-2;
  }
  .app-container[data-sidenavsize="XS"] .app-main,
  .app-container[data-sidenavsize="XS_2"] .app-main {
    grid-column-start: col-3;
  }
  .app-container[data-sidenavsize="S"] .app-main {
    grid-column-start: col-4;
  }
  .app-container[data-sidenavsize="M"] .app-main {
    grid-column-start: col-5;
  }
  .app-container[data-sidenavsize="L"] .app-main {
    grid-column-start: col-6;
  }
  .app-container[data-sidenavsize="XL"] .app-main {
    grid-column-start: col-7;
  }
  .app-container[data-sidenavsize="XXL"] .app-main {
    grid-column-start: col-8;
  }
  .app-container[data-sidenavsize="XXXL"] .app-main {
    grid-column-start: col-9;
  }
  .app-container[data-sidenavsize="XXXXL"] .app-main {
    grid-column-start: col-10;
  }
  .app-container[data-sidenavsize="XXXXXL"] .app-main {
    grid-column-start: col-11;
  }

  /*...................................................*/

  /*...........Logo Width Style..............*/

  .app-container[data-logowidthsize="XXXS"] .app-brand {
    grid-column-end: col-1;
  }
  .app-container[data-logowidthsize="XXS"] .app-brand {
    grid-column-end: col-2;
  }
  .app-container[data-logowidthsize="XS"] .app-brand {
    grid-column-end: col-3;
  }
  .app-container[data-logowidthsize="S"] .app-brand {
    grid-column-end: col-4;
  }
  .app-container[data-logowidthsize="M"] .app-brand {
    grid-column-end: col-5;
  }
  .app-container[data-logowidthsize="L"] .app-brand {
    grid-column-end: col-6;
  }
  .app-container[data-logowidthsize="XL"] .app-brand {
    grid-column-end: col-7;
  }
  .app-container[data-logowidthsize="XXL"] .app-brand {
    grid-column-end: col-8;
  }
  .app-container[data-logowidthsize="XXXL"] .app-brand {
    grid-column-end: col-9;
  }
  .app-container[data-logowidthsize="XXXXL"] .app-brand {
    grid-column-end: col-10;
  }
  .app-container[data-logowidthsize="XXXXXL"] .app-brand {
    grid-column-end: col-11;
  }

  .app-container[data-grid="S0"] .app-topnav {
    grid-column-start: col-9;
  }
  .app-container[data-logowidthsize="XXXS"]:not([data-logoonnav="TRUE"])
    .app-topnav {
    grid-column-start: col-1;
  }
  .app-container[data-logowidthsize="XXS"]:not([data-logoonnav="TRUE"])
    .app-topnav {
    grid-column-start: col-2;
  }
  .app-container[data-logowidthsize="XS"]:not([data-logoonnav="TRUE"])
    .app-topnav {
    grid-column-start: col-3;
  }
  .app-container[data-logowidthsize="S"]:not([data-logoonnav="TRUE"])
    .app-topnav {
    grid-column-start: col-4;
  }
  .app-container[data-logowidthsize="M"]:not([data-logoonnav="TRUE"])
    .app-topnav {
    grid-column-start: col-5;
  }
  .app-container[data-logowidthsize="L"]:not([data-logoonnav="TRUE"])
    .app-topnav {
    grid-column-start: col-6;
  }
  .app-container[data-logowidthsize="XL"]:not([data-logoonnav="TRUE"])
    .app-topnav {
    grid-column-start: col-7;
  }
  .app-container[data-logowidthsize="XXL"]:not([data-logoonnav="TRUE"])
    .app-topnav {
    grid-column-start: col-8;
  }
  .app-container[data-logowidthsize="XXXL"]:not([data-logoonnav="TRUE"])
    .app-topnav {
    grid-column-start: col-9;
  }
  .app-container[data-logowidthsize="XXXXL"]:not([data-logoonnav="TRUE"])
    .app-topnav {
    grid-column-start: col-10;
  }
  .app-container[data-logowidthsize="XXXXXL"]:not([data-logoonnav="TRUE"])
    .app-topnav {
    grid-column-start: col-11;
  }
  /*.............................................*/

  /*..........Floating Menu.........*/
  .app-container[data-floatingmenu="SIDENAV"] .sidebar .sidebar-wrapper {
    min-height: unset;
    max-height: unset;
  }
  .app-container[data-floatingmenu="SIDENAV"] .sidebar {
    height: auto;
    padding-top: 10px;
    padding-bottom: 10px;

    margin-bottom: 55px;
  }
  .app-container[data-floatingmenu="SIDENAV"] .app-sidenav {
    align-items: center;
    display: flex;
  }

  .app-container:not([data-rounded="FALSE"]):not([data-padded="FALSE"])[data-floatingmenu="SIDENAV"]
    .sidebar {
    border-radius: 5px !important;
  }
  .app-container:not([data-rounded="FALSE"])[data-padded="FALSE"][data-floatingmenu="SIDENAV"]
    .sidebar {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }

  /*.............Contained Theme...........*/
  .wrapper[data-contained="TRUE"] {
    padding: 8px;
  }
  .wrapper[data-contained="TRUE"] .app-container {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 1rem 3rem rgba(34, 42, 66, 0.175);
  }

  .app-container[data-boxed="FALSE"][data-tnshadow="TRUE"]:not([data-logoonnav="TRUE"])
    .app-brand,
  .app-container[data-boxed="S_LN"][data-tnshadow="TRUE"] .app-brand,
  .app-container[data-boxed="SLN"][data-tnshadow="TRUE"] .app-brand,
  .app-container[data-tnshadow="TRUE"]:not([data-boxed="SL_N"]) .app-topnav {
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
  }
}
